/* PhotoViewer.css */

.modalContainer {
  display: flex;
  align-items: left;
  justify-content: left;
  height: 100vh;
  background: rgba(226, 228, 230, 1);
}

.modal-title-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-close-btn {
  padding: 2px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .modal-close-btn {
    font-size: 16px;
  }
}

.modalContent {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.title {
  height: 4vh;
  text-align: left;
  font-weight: 400px;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
}

.imageContainer {
  height: 80vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  background-color: #1b1b1f;
  border-radius: 1%;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.buttonSection {
  display: flex;
  justify-content: space-between;
  margin: 16px;
  margin-bottom: 15px;
}

.buttonContainer,
.doneButtonContainer {
  display: flex;
  gap: 8px;
}

.doneButtonContainer {
  margin-left: auto;
}

/* styles for mobile */
@media only screen and (max-width: 768px) {
  .modalContent {
    padding: 8px;
    max-height: 90vh;
    overflow-y: auto;
  }

  .title {
    font-weight: 200px;
    font-size: 14px;
  }

  .imageContainer {
    height: calc(40vh + 0.1 * (100vh - 768px));
  }

  .buttonSection {
    flex-direction: column;
    align-items: center;
  }

  .buttonContainer,
  .doneButtonContainer {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .doneButtonContainer {
    margin-left: 0;
    margin-top: 8px;
  }
}

/* style for tab  */
@media all and (max-height: 780px) {
  .title {
    height: 5vh;
  }

  .imageContainer {
    height: calc(73vh + 0.1 * (100vh - 780px));
  }
}

/* Styles for laptops and desktops go here */
@media only screen and (min-width: 1240px) {
  .imageContainer {
    height: 90vh;
  }
}
