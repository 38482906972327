/* CSS FOR Scheduler TImebar  */
.fc-timegrid-now-indicator-container {
  overflow: visible !important;
  .fc-timegrid-now-indicator-line {
    width: calc(1000% + 4px) !important;
  }
}
.fc-day-tue {
  .fc-timegrid-now-indicator-line {
    transform: translateX(-20%);
  }
}
.fc-day-wed {
  .fc-timegrid-now-indicator-line {
    transform: translateX(-40%);
  }
}
.fc-day-thu {
  .fc-timegrid-now-indicator-line {
    transform: translateX(-60%);
  }
}
.fc-day-fri {
  .fc-timegrid-now-indicator-line {
    transform: translateX(-80%);
  }
}

.header-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.today-date {
  display: flex;
  flex-direction: column; /* Stack the circle and weekday */
  align-items: center; /* Align items to the left */
  position: relative;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  border-radius: 50%; /* Make it a circle */
  background-color: #0071e3; /* Circle color */
  color: white; /* Text color */
  font-weight: bold; /* Make the number bold */
  margin-bottom: 5px; /* Space between circle and weekday */
}

.weekday {
  color: #212121; /* Color for the weekday text */
  font-weight: normal;
  /* Normal weight for the weekday text */
}
.active {
  color: #0071e3; /* Color for the weekday text */
  font-weight: normal;
}

.fc-timeGridDay-view .fc-scrollgrid-sync-inner {
  text-align: left !important;
  padding: 0.5em;
  width: 100%;
}

.fc-non-business {
  background-color: #e8e7e7a3 !important;
  border-radius: 4px !important;
  margin: 2px; /* Rounded corners for the block effect */
}

.adas-calendar .fc-timegrid-slot {
  background-color: #ffffff !important;
}
.fc .fc-col-header-cell-cushion {
  width: inherit !important;
}
