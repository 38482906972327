/* .Calibration-Bar-Main-Stage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  margin-right: auto;
  margin-left: auto;
}

.Calibration-Bar-Content {
  background-color: white;
  border-radius: 4px;
  margin-top: .75rem;
  margin-right: 1.5rem;
  margin-top: .75rem;
  margin-right: 1.5rem;
  border: 1px solid;
} */

.popper-top {
  z-index: 9999 !important;
}

@media (max-width: 600px) {
  .popper-top {
    z-index: 9999 !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}
