.navigation-bar {
  position: relative;
  height: 100dvh;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.top-section {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  max-width: 100%;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  text-decoration: none;
  min-height: 48px;
  padding: 6px 16px;
}

.menu-list-container {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  width: 100%;
}

.menu-list-container::-webkit-scrollbar {
  width: 1px; /* Adjust width as needed */
}

.menu-list-container::-webkit-scrollbar-track {
  background: transparent; /* Scrollbar track color */
}

.menu-list-container::-webkit-scrollbar-thumb {
  background: transparent; /* Scrollbar thumb color */
  border-radius: 0px;
}

.menu-list-container::-webkit-scrollbar-thumb:hover {
  background: transparent; /* Thumb color on hover */
}

.menu-list-container li {
  font-size: 0.9rem;
}

.toggle-button {
  position: absolute;
  top: 34%;
  z-index: 1000;
  right: 0;
  cursor: pointer;
  display: flex;
  padding: 6px 16px;
}

.toggle-button.closed {
  justify-content: center;
}

.menu-arrow {
  font-weight: 600;
  justify-content: right;
  margin-right: -1rem;
}

.menu-arrow-closed {
  align-items: center;
  margin: auto;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: end;
  text-align: center;
  margin-top: auto;
  /* margin-bottom: 50px; */
}

.footer-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: auto;
}

.footer-text {
  font-weight: 600;
  font-size: 0.7rem !important;
  text-align: center;
  color: var(--secondary-main);
}

.footer-version-box {
  overflow: hidden;
  position: sticky;
  bottom: 0;
  left: 0;
  padding-bottom: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.footer-version {
  color: var(--secondary-main);
  font-weight: 600;
  font-size: 12px !important;
}

.adas-logo {
  height: 55%;
  width: 55%;
}

/* Responsive styles for mobile and desktop */
@media (max-width: 435px) {
  .desktop-menu-container {
    display: none;
  }

  .mobile-menu-container {
    display: block;
  }
  .mobile-version-section {
    margin-bottom: 20px;
    padding-left: 20px;
  }
  .footer img {
    width: 45%;
  }
}

@media (min-width: 435px) {
  .desktop-menu-container {
    display: block;
  }

  .mobile-menu-container {
    display: none;
  }

  .navigation-bar {
    width: 21vw;
  }

  .navigation-bar.collapsed {
    width: 3.25rem;
  }
}
