.wordWrap {
  word-wrap: break-word;
}

.adas-card {
  margin: 17px 24px;
  padding: 16px 16px;
  background-color: #ffffff;
}

.drawer-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 24px !important;
}
.event-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #0071e3;
  margin-right: 8px; /* Space between the dot and the text */
  animation: blink 1s infinite; /* Animation: 1 second duration, infinite loop */
}
@keyframes blink {
  0%,
  100% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Fully invisible */
  }
}
