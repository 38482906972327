.AdasButtonGroup {
    box-shadow: none;
    width: 300px 
}
.AdasButtonGroupParent{
    float: left
}

@media (max-width: 768px) {
    .seachmenu {
      width: 100%;
    }
    
    .AdasButtonGroup {
        width: 100%;
        margin-bottom: 10px;
    }
    .AdasButtonGroupParent{
        float: none
    }  
  }
  