.fc-v-event {
  background-color: transparent !important;
  border: none;
}
/* custom.css */
.fc .fc-event {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}

.fc .fc-v-event .fc-event-main {
  padding-left: 4px !important;
}

.fc .fc-daygrid-event-dot {
  background-color: transparent !important;
  border: none !important;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent !important;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: transparent !important;
}
/* .fc-timegrid-slot.fc-timegrid-slot-label.fc-timegrid-slot-minor {
  height: 50px !important;
} */
/* events should be top of dateoverride */
.fc-timegrid-col-events {
  z-index: 10 !important;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: none !important;
}

/* full calender Pop over should be under the create event pop up*/
.fc .fc-popover {
  z-index: 1000 !important;
}