.drawer {
  margin-left: 20px;
  margin-right: 20px;
}

.drawer .title {
  font-weight: 400;
  font-size: 12px;
  margin-top: 20px;
  text-align: center;
  color: rgba(26, 27, 31, 0.6);
  border-bottom: 1px solid rgba(26, 27, 31, 0.6);
}

.drawer .description {
  font-size: 16px;
}
