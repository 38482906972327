.vehicle {
  /* border-radius: 6px;
  box-shadow: 0 1px 4px rgba(252, 249, 249, 0.2);
  padding: 1rem; */
  text-align: center;
}

.vehicle h1 {
  text-align: left;
  font-size: 28px;
  color: rgb(0, 0, 0);
  font-weight: normal;
}

.vehicle .formField {
  background-color: white;
  margin-bottom: 10px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.formField label {
  margin-top: 6px;
  font-size: 16px;
}

.leftField,
.rightField {
  width: 49%;
}

.leftField {
  float: left;
}

.rightField {
  float: right;
}

.vehicle .customSlider {
  display: flow-root;
}

h6 {
  text-align: left;
  margin: 2px;
}

.submit {
  text-align: right;
  overflow: auto;
}

.submit button {
  margin-bottom: 50px;
  margin-top: 10px;
  text-transform: none;
}

.manualInput {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.drawer {
  margin-left: 20px;
  margin-right: 20px;
}

.drawer .title {
  font-weight: 400;
  font-size: 12px;
  margin-top: 20px;
  text-align: center;
  color: rgba(26, 27, 31, 0.6);
  border-bottom: 1px solid rgba(26, 27, 31, 0.6);
}

.drawer .description {
  font-size: 16px;
}
