.queue-item {
  border-bottom: 1px solid #bdbdbd;
  padding-bottom: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.queue-item-content {
  width: 100%;
}

.queue-item-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  cursor: pointer;
  align-items: center;
}

.queue-item-id-display {
  float: left;
}

.queue-item-status {
  margin: auto;
  margin-right: 1.8rem;
}

.queue-item-status-icon {
  display: flex;
  align-items: center;
}

.queue-item-info {
  text-align: right;
}

.queue-item-body {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  overflow: auto;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

.queue-item-body-left {
  float: left;
}

.queue-item-body-right {
  float: right;
  text-align: end;
}

.queue-item-footer {
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invoice-expired {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #ff0000;
}

.scorecard-icon {
  margin-right: 0.5rem;
}

.vehicle-info {
  font-size: 20px;
}

.vehicle-client {
  font-size: 20px;
}

.status-info {
  margin-left: 10px;
  cursor: pointer;
}

.aged-invoice-label {
  margin-top: -15px;
}

.date-info {
  align-self: center;
}
.display-none-for-bigger-devices {
  display: none;
}

@media (max-width: 435px) {
  .queue-item {
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: 10px;
    margin-bottom: 20px;
    display: flex;
  }

  .queue-item-content {
    width: 100%;
  }

  .queue-item-header {
    display: flex;
    /* grid-template-columns: 1fr auto 1fr; */
    overflow: auto;
    font-size: 12px;
    justify-content: space-between;
    margin-bottom: 10px;
    cursor: pointer;
    align-items: center;
  }

  .queue-item-id-display {
    float: left;
    font-size: 12px;
  }

  .queue-item-status {
    margin: auto;
    margin-right: 1.8rem;
  }

  .queue-item-status-icon {
    display: flex;
    align-items: center;
  }

  .queue-item-info .css-1anhkff-MuiChip-root {
    text-align: right;
    font-size: 12px;
  }

  .queue-item-body {
    overflow: auto;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .queue-item-footer {
    font-size: 12px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .css-i8ofro-MuiTypography-root {
    font-size: 12px !important;
  }
  .invoice-expired {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #ff0000;
  }

  .scorecard-icon {
    margin-right: 0.5rem;
  }

  .vehicle-info {
    font-size: 12px !important;
  }

  .vehicle-client {
    font-size: 12px !important;
  }

  .status-info {
    margin-left: 10px;
    cursor: pointer;
    font-size: 12px;
  }

  .aged-invoice-label {
    margin-top: -15px;
  }

  .date-info {
    text-align: right;
    font-size: 12px;
  }

  .queue-item-radio {
    margin-left: -10px !important;
    height: fit-content !important;
  }

  .display-none-for-mobile {
    display: none;
  }
}

@media (min-width: 800px) {
  .queue-item-content.is-option-enabled {
    margin-left: 3%;
  }
}
