.logo {
  margin: 3rem auto;
  width: 95%;
}

img {
  display: block;
  width: 65%;
  margin-left: auto;
  margin-right: auto;
}
