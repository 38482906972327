:root {
  --primary-main: #0071e3;
  --secondary-main: #8a8a8a;
  --background-default: #ffffff;
  --background-highlight: #96f7b8;
  --background-shaded: #1a1b1f80;
  --background-lightGray: #e1e9f0;
  --info-main: #001b3f;
  --info-light: #d7e2ff;
  --error-main: #ba1a1a;
  --error-light: #ffdad6;
  --fullScreenHeight: 100vh;
}

.adas-search-menu {
  font-size: 14px;
  background-color: white;
  color: var(--primary-main);
  max-width: 150px;
  width: 150px;
}

.adas-search-menu .MuiInputBase-root {
  padding-right: 0px;
}

.adas-search-menu .MuiSelect-select {
  color: var(--primary-main);
  font-size: 14px;
}

.adas-search-menu #assignee {
  padding: 5px;
}

@media (max-width: 425px) {
  .adas-form-control {
    font-size: 12px;
  }

  .adas-search-menu {
    font-size: 14px;
    background-color: white;
    color: var(--primary-main);
    width: 50%;
  }

  .adas-search-menu .MuiInputBase-root {
    padding-right: 0px;
  }

  .adas-search-menu .MuiSelect-select {
    color: var(--primary-main);
    font-size: 12px;
  }

  .adas-search-menu #assignee {
    padding: 5px;
  }

  .adas-input-adornment .edit-icon {
    height: 15px;
  }
}
