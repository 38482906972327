.section {
  border: 1px solid rgba(0, 0, 0, 0.223);
  border-top: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

#image h2 {
  text-align: center;
}

.row {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
}

.column {
  flex: 50%;
  max-width: 50%;
}

.section span {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.label {
  color: #0009;
  font-size: 12px;
  margin-top: 10px;
}

.data {
  font-size: 16px;
}

.safety {
  background-color: #e8f5e9;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  color: #1b5e20;
  margin-bottom: 20px;
  margin-top: 20px;
}

.safety h3 {
  margin-bottom: 0px;
  margin-top: 0px;
}

ul {
  list-style-type: none;
  padding-left: 0px;
  text-align: left;
  margin-top: 0px;
}

li .icon {
  vertical-align: bottom;
}

.section .add {
  display: flex;
  justify-content: end;
}
