.notification-message-section {
  background-color: #fffaca;
  min-height: 70px;
  padding: 0.1% 1%;
  border-radius: 6px !important;
}
.critical {
  background: rgba(252, 229, 227, 1);
}
.notification-message-content-title {
  display: flex;
  align-items: center;
  height: 2rem !important;
  margin-top: 7px;
}

.notification-subject {
  margin-left: 5px !important;
  width: 95%;
  font-weight: 700 !important;
  font-size: 14px !important;
}

.notification-message {
  margin-left: 36px;
  font-size: 14px !important;
}

@media (max-width: 435px) {
}
